import React,{useState} from 'react'
import styled from 'styled-components'
import { motion } from "framer-motion"





export const TextInput = (
    {label, type, name, autocomplete, 
        required, value, onChange, onBlur, id,
        onFocus, placeholder, error, errorMessage,...props}
    )=>{

    const [isFocus, setIsFocus] = useState(false);
    const valueNotActive = (value==='' || value===undefined || value===null )

    return(
        <InputContainer className="form-group" isFocus={isFocus}> 

            <InputLabel htmlFor={name} isFocus={isFocus} valueNotActive={valueNotActive}>
                {label? label:'label'}  {required? '*':''}
            </InputLabel>

            <Input type={type}
                id={id} aria-describedby="emailHelp" 
                onError={()=>error()} name={name} value={value} 
                onChange={(e)=>{
                    //if onChange is passed as a prop, use it
                    if(onChange) return onChange(e.target.value);
                    //else use the default onChange
                    return null;
                }} onBlur={()=>{
                    //chech if onfocus is passed
                    if(onBlur){} //if onfocus is passed, call it
                    return setIsFocus(false);   //if not, set focus to false
                    
                    
                }} onFocus={()=>{
                    //check if onfocus is passed
                    if(onFocus) return onFocus(); //if onfocus is passed, call it
                    return setIsFocus(true); //if not, set isFocus to true
                }} 
                outFocus={()=>setIsFocus(false)} 
                autocomplete={`${autocomplete? autocomplete: true}`}
                required={required? required : false}
            />

        </InputContainer>
    )
}


const InputContainer = styled.div`
    position: relative;
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: #EDEEF0;
    padding: 10px;
    border:  ${props => props.isFocus ?  `1px #403BF1 solid`: '1px #DBDBDB solid'};
    transition:  0.2s ease-in-out;
    &:after {
        transition:  0.2s ease-in-out;
    }
`

const InputLabel = styled.label`
    font-size: 12px;
    font-weight: 400;
    position:absolute;
    font-family: 'Open Sans', sans-serif;
    line-height: ${props => props.valueNotActive && !props.isFocus?  '45px': '7px' };
    color: ${props => props.isFocus ?  '#403BF1': '#7D7D7D'};
    transition:  0.2s ease-in-out;
    &:after {
        transition:  0.3s ease-in-out;
    }
    z-index: ${props => props.valueNotActive && !props.isFocus?  1: 20 };;
`

const Input = styled.input`
-webkit-tap-highlight-color: rgba(0,0,0,0);
    position: relative;
    width: 100%;
    height: 100%;
    border: none;
    padding:5px 0px 0px 0px;
    background-color:transparent;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    color: #525252;
    transition:  0.2s ease-in-out;
    z-index: 10;
    &:focus{
        outline: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
    @media screen and (-webkit-min-device-pixel-ratio:0){
        font-size: 16px;
    }
}
`





const hoverMotion = {
    rest: {ease: "easeOut", duration: 0.2, type: "tween"},
    hover: {
        scale: 1.005, 
        transition: {
            duration: 0.2,
            ease: "easeIn",
            type: "tween"
        }
        
    }
}



export const Selectable=({index,title, label, selected, onClick})=>{
    return(
        <SelectableContainer key={index} selected={selected? selected:false}  initial='rest'  whileHover="hover" variants={hoverMotion}>
            <Touchable onClick={()=>onClick(true)}/>
            <Box>
                {/* <input checked={selected} htmlFor="checkbox" type="checkbox" id="checkbox" /> */}
                <div className="round">
                    <input type="checkbox" onChange={()=>onClick(true)} checked={selected} id="checkbox" />
                    <label htmlFor="checkbox"></label>
                </div>
            </Box>

            <CheckLabel>
                <span>{title.charAt(0).toUpperCase() + title.slice(1)}</span>
                <label >{label}</label>
            </CheckLabel>
        </SelectableContainer>
    )
}


const SelectableContainer = styled(motion.div)`
    position: relative;
    height: auto;
    width:100%;
    margin: 0px auto;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    border:  ${props => props.selected ?  `1px #403BF1 solid`: 'none'};
    background-color: #EDEEF0;
    transition:  0.2s ease-in-out;
    align-items: center;

    &:after {
        transition:  0.2s ease-in-out;
    }
    &:hover{
        box-shadow: 0px 2px 4px #CDCDCD;
    }
    &:focus{
        outline: none;

    }
    
`

const Touchable = styled.div`
    z-index: 10;
    position: absolute;
    bottom: -.9px;
    left: -0.5px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding: 0px;
    background: transparent;
    transition:  0.2s ease-in-out;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    &:focus{
        outline: none;
        
    }
`

const Box = styled.div`
    height: 100%;
    width: 35px;
    text-align:center;
    padding: 10px;
    &:after {
        transition:  0.2s ease-in-out;
    }
    &>div{
        position: relative;
        display: inline-block;
        &>label{
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 50%;
            height: 15px;
            left: 0;
            position: absolute;
            top: 0;
            width: 15px;
            &:after {
                border: 2px solid #fff;
                border-top: none;
                border-right: none;
                content: "";
                height: 3px;
                width: 7px;
                left: 2.7px;
                top: 3.9px;
                opacity: 0;
                position: absolute;
                transform: rotate(-45deg);
            }
        }
        &>input{
            visibility: hidden;
        }

        &>input:checked + label {
            background-color: #403BF1;
            border-color: #403BF1;            
            &:after {
                opacity: 1;
            }
        }

    }
`


const CheckLabel = styled.div`
    display: flex;
    flex-direction: column;
    height:100%;
    padding: 12px 0px;
    & > span{
        font-size: 15px;
        font-weight: 500;
        font-family: 'Open Sans', sans-serif;
        color: #464646;
        transition:  0.2s ease-in-out;
        padding: 5px 0px;
    }
    & > label{
        font-size: 13px;
        line-height:1.5;
        font-weight: 400;
        font-family: 'Open Sans', sans-serif;
        color: #7D7D7D;
        transition:  0.2s ease-in-out;
        padding: 0px 15px  5px 0px;
        max-width: 320px;
    }
`












export const SubmitButton = ({text, icon, onClick, src, disabled, textColor, color, textWeight})=>{
    return(
        <Button color={color} whileHover={disabled? null:{ scale: 1.05 }} 
            whileTap={disabled? null:{ scale: 0.95 }} disabled={disabled} 
            type="submit" onClick={onClick}>
            <ImgText textWeight={textWeight} textColor={textColor}>{text? text:"Submit"}</ImgText> 
            {icon? <Img className="icon">
                <img style={{height:'100%', verticalAlign:'middle',margin:"0px 0px 10px 0px", backgroundColor:""}} 
                alt='buttonIcon' src={src}/>
            </Img>: null}
        </Button>
    )
}



const Button = styled(motion.button)`
    background-color: ${props =>props.color? props.color: props.disabled ? '#635FFF':'#4540F8'};
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    align-items: center;
    display:flex;
    border-radius:5px;
    padding:15px;
    margin:10px 0px 0px 0px;
    justify-content:center;
    width:100%;
    text-align:center;
    border:none;
    cursor:pointer;
`;


const ImgText = styled.span` 
    font-size:15px;
    font-weight:${props =>props.textWeight? props.textWeight: '400'};
    color:${props =>props.textColor? props.textColor: '#fff'};
    font-family:Open Sans;
    line-height:2;
    max-width:450px;
`;



const Img = styled.div`
    height:8px;
    width:8px;
    padding:8px;
    margin:0px 0px 0px 30px;
    border:solid white 1px;
    border-radius:50%;
    align-items:center;
    vertical-align:middle;
`














export const Text = styled(motion.p)`
    display:flex;
    padding:${props => props.pad? props.pad: '0px'};
    text-align:${props => props.align ? props.align : ''};
    font-size: ${props => props.size ? props.size : '15px'};
    font-weight: ${props => props.weight ? props.weight : '400'};
    color: ${props => props.color ? props.color : '#424242'};
    font-family: ${props => props.font ? props.font : 'Open Sans'};
    line-height:${props => props.lineHeight ? props.lineHeight : '2'};
    max-width:${props => props.width ? props.width : '450px'};
    margin:${props => props.margin ? props.margin : '0px'};
    top:${props => props.top ? props.top : ''};
    left:${props => props.left ? props.left : ''};
    position:${props => props.position ? props.position : 'relative'};
    bottom:${props => props.bottom ? props.bottom : ''};
    right:${props => props.right ? props.right : ''};
    z-index:${props => props.zIndex ? props.zIndex : ''};
    width:${props => props.rwidth ? props.rwidth : ''};
    background-color:${props => props.bgColor ? props.bgColor : ''};
    @media (max-width: ${props => props.after? props.after : ''}) {
        text-align:${props => props.afterAlign ? props.afterAlign : ''};
        font-size:${props => props.afterSize ? props.afterSize : '15px'};
        max-width:${props => props.afterWidth ? props.afterWidth : '450px'};
    }
    @media (max-width: 560px) {
      font-size:${props => props.minSize ? props.minSize : ''};
    }

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`


