import React, { useEffect } from 'react';
import { Home } from './pages/home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import { Header } from './components/header';
import pageData from './dataProvider/pageData';
import RaiseCapital from './pages/raiseCapital';
import { Join } from './pages/join';
import Invest from './pages/invest';
import Footer from './components/footer';
import TestWaters from './pages/testTheWaters';
import ErrorBoundary from './module/errorBoundary'
import ScrollToTop from './module/scrollToTop';








function App(){
  
  useEffect(() => {
    const imageList =[...pageData.investor.firstSection.sideImg, pageData.mainContent.sideImg];

    imageList.forEach(img => {
      const imgElement = new Image();
      imgElement.src = img;
      imgElement.src = pageData.mainContent.sideImg;
    });

  }, []);

  return(
    <>
      <Router>
        <ScrollToTop>
          <Header/>
          <Switch>
            <Route exact path={pageData.headerComp.home.route}>
              <Home/>
            </Route>

            <Route exact path={pageData.headerComp.invest.route}>
              <Invest/>
            </Route>
    
            <Route exact path={pageData.headerComp.raiseCapital.route}>
              <ErrorBoundary>
                <RaiseCapital/>
              </ErrorBoundary>
            </Route>

            <Route exact path={pageData.headerComp.join.route}>
              <Join/>
            </Route> 

            <Route exact path={pageData.testWaters.route}>
              <TestWaters/>
            </Route>

          </Switch>
          <Footer/>
        </ScrollToTop>
      </Router>
    </>
  )
}






export default App;
