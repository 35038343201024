import React, {useRef, useEffect, useState } from 'react';
import pageData from '../dataProvider/pageData';
import { useLocation,Link } from 'react-router-dom';
import { motion, useCycle } from "framer-motion";
import {useWindowScroll} from './windowSize';



  


//Get page height
const useDimensions = ref => {
    const dimensions = useRef({ width: 0, height: 0 });
    useEffect(() => {
      dimensions.current.width = ref.current.offsetWidth;
      dimensions.current.height = ref.current.offsetHeight;
    }, [ref]);
    return dimensions.current;
};
  

  
  
  
  
  
  
  
const Navigation = ({isOpen, toggleOpen, isHomeActive, isRaise}) => (
    <motion.ul  className='toggleUl' variants={{open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }}}>

        <div style={{height:`${isOpen? "":"0px"}`, }} className='nav-container'>
        <motion.li className='toggleLi' variants={sidebar.item} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
            <Link onClick={()=>toggleOpen()} style={{...btnStyle, color:`${isHomeActive? "#403bf1":'#484848'}` }} to={`${pageData.headerComp.invest.route}`} className={"isHomeActive"} >{pageData.headerComp.invest.name}</Link>
        </motion.li>


        <motion.li className='toggleLi' variants={sidebar.item} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
            <Link onClick={()=>toggleOpen()} style={{...btnStyle, color:`${isRaise? "#403bf1":'#484848'}` }} to={`${pageData.headerComp.raiseCapital.route}`} className={"isRaise"} href="#">{pageData.headerComp.raiseCapital.name}</Link>
        </motion.li>


        <motion.li className='toggleLi' variants={sidebar.item} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
            <a style={{textDecoration: "none"}} className="community" href="https://openstake.slack.com/">{pageData.headerComp.community.name}</a>
        </motion.li>

        <motion.li className='toggleLi' variants={sidebar.item} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
            <Link onClick={()=>toggleOpen()} to={`${pageData.headerComp.join.route}`} style={{textDecoration: "none"}} className="join" href="#">{pageData.headerComp.join.name}</Link>
        </motion.li>
        </div>
    </motion.ul>
  );
  
  
  
  
  
  
const Path = props => (
    <motion.path fill="transparent"
        strokeWidth="3" stroke="#000"
        strokeLinecap="round" {...props}
    />
);
  
  
const MenuToggle = ({ toggle }) => (
    <button className="toggleBtn" onClick={toggle}>
        <svg style={{paddingTop:'8px', paddingLeft:'2px'}} width="23" height="23" viewBox="0 0 23 23">
        <Path variants={{ closed: { d: "M 2 2.5 L 20 2.5" }, open: { d: "M 3 16.5 L 17 2.5" }}}/>
        <Path d="M 2 9.423 L 20 9.423" variants={{closed: { opacity: 1 },open: { opacity: 0 }}} transition={{ duration: 0.1 }}/>
        <Path variants={{closed: { d: "M 2 16.346 L 20 16.346" },open: { d: "M 3 2.5 L 17 16.346" }}}/>
        </svg>
    </button>
);
  
  
  
  
  
  
const sidebar = {
    open: (height = 1000) => ({
          clipPath: `circle(${height * 3 + 200}px at -100% -40px)`,
          transition: {
            type: "spring",
            stiffness: 100,
            restDelta: 2
          }
        }),
        closed: {
          clipPath: "circle(30px at 105% 40px)",
          transition: {
            delay: 0.2,
            type: "spring",
            stiffness: 400,
            damping: 40
          }
        },
        item:{open: {
          y: 0,
          opacity: 1,
          transition: {
            y: { stiffness: 1000, velocity: -100 }
          }},
        closed: {
          y: 50,
          opacity: 0,
          transition: {
            y: { stiffness: 1000 }
          }}}
    
  };
  
  


const Sidebar = ({isHomeActive, isRaise}) => {
    const [isOpen, toggleOpen] = useCycle(false, true);
    const containerRef = useRef(null);
    const { height } = useDimensions(containerRef);
  
    return (
      <motion.nav style={{height:`${isOpen? "":"0px"}`, }} className="sidebar" initial={false} animate={isOpen ? "open" : "closed"} custom={height} ref={containerRef}>
        <motion.div className="background" variants={sidebar} />
        {isOpen?<Navigation isOpen={isOpen} toggleOpen={toggleOpen} isHomeActive={isHomeActive} isRaise={isRaise}/>:null}
        <MenuToggle toggle={() => toggleOpen()} />
      </motion.nav>
    );
};
  


const btnStyle={
    texDecoration: "none",
  fontSize: "14px",
  fontFamily: "Open Sans",
  margin: "0px 15px",
  fontWeight: "400",
  color: "#484848",
  textDecoration: "none",
  width: "100%",
  textAlign: "center",
}










export const Header = ({showAtHeight})=>{
    const location = useLocation();
    const [isHomeActive, setIsHomeActive] = useState('');
    const [isRaise, setIsRaise] = useState('');
    const {scrollPositon, atHeight}= useWindowScroll(showAtHeight? showAtHeight:showAtHeight=0);

    useEffect(()=>{
      if(location.pathname === '/invest'){
          setIsHomeActive('active');
          setIsRaise('')
      }else if( location.pathname==='/raisecapital'){
          setIsHomeActive('');
          setIsRaise('active')
      }else{
          setIsHomeActive('');
          setIsRaise('actve')
      }

    },[location.pathname, atHeight])


    return(
        <div className={`header-body ${atHeight? "headerScroll":''}`}>
            <nav className="header-nav">
                <div className="logo-body">
                    <Link to="/">
                      <img alt={pageData.headerComp.home.name} src={pageData.headerComp.logo}/>
                    </Link>
                </div>

                {/* {width>00?} */}
                <div className="middle-links">
                    <Link to={`${pageData.headerComp.invest.route}`} className={isHomeActive} >{pageData.headerComp.invest.name}</Link>
                    <Link to={`${pageData.headerComp.raiseCapital.route}`} className={isRaise} href="#">{pageData.headerComp.raiseCapital.name}</Link>
                </div>

                <div className="end-links">
                    <a className="community" href="https://openstake.slack.com/">{pageData.headerComp.community.name}</a>
                    <Link to={`${pageData.headerComp.join.route}`}  style={{textDecoration:"none"}} className="join">{pageData.headerComp.join.name}</Link>
                </div>

                <Sidebar isHomeActive={isHomeActive} isRaise={isRaise}/>
            </nav>
        </div>
    )
}


