import React from 'react';
import styled from 'styled-components';
import data from '../dataProvider/pageData'
import {SubmitButton} from './input' 


const PageSection_5 = () => {
    return (
        <Container>
            <Board img={data.mainContent.fifthSection.background[0]}>
                <Head>{data.mainContent.fifthSection.title}</Head>
                
                <Cover>
                    <SubmitButton text={data.mainContent.fifthSection.buttonText}
                        onClick={()=> window.location = "mailto:timmyodunuga@gmail.com"}
                    />
                </Cover>
            </Board>
        </Container>
    );
}




const Container = styled.div`
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
    display: block;
`
const Board = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    min-height: 300px;
    border-radius: 10px;
    background: #EBEDFD;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(${props => props.img}), url(${props => props.img});
    background-repeat: no-repeat, no-repeat;
    background-size: contain, contain;
    background-position: left, right;
    ${'' /* margin-bottom: 20px; */}
`

const Cover = styled.div`
    width: 250px;
    padding: 20px;

`

const Head=styled.h1`
    font-size: 28px;
    font-weight: 500;
    color: #2D2D2D;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    padding: 30px 20px;
    margin: 0 auto;
    display: block;
    max-width: 600px;
    line-height: 2;
    @media (max-width: 600px) {
        font-size: 24px;
    }
`




export default PageSection_5;

