import { useState, useEffect } from 'react'




////get updated account
// const subscribeToWindowSize = (web3, account = callback) => {
//   const id = setInterval(async () => {
//       try {

//       } catch (err) {
//           account(callback(err, null))
//       }
//   }, 1000)

//   return () => {
//       clearInterval(id)
//   }
// }





function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  


export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
}



export function useWindowScroll(height) {
  const [scrollPositon, setScrollPositon] = useState(window.pageYOffset);
  const [atHeight, setAtHeight] = useState(false);

  useEffect(() => {
    setScrollPositon(window.pageYOffset);

    window.addEventListener('scroll',handleScroll );
    return () => window.removeEventListener('scroll',handleScroll );
  }, [scrollPositon]);

  const handleScroll = () => {
    setScrollPositon(window.pageYOffset);
    if (window.pageYOffset > height) {
      setAtHeight(true);
    } else {
      setAtHeight(false);
    }
  }


  return {scrollPositon, atHeight};
}