import React, {useEffect} from "react";
import styled from "styled-components";
import pageData from '../dataProvider/pageData';
import { motion } from "framer-motion"
import { SubmitButton, Text } from "../components/input";
import { useHistory } from "react-router-dom";
import MetaTags from 'react-meta-tags';



const cont = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.8,
            staggerChildren: 0.2
        }
    }
};


const item = {
    hidden: { x: 50, opacity: 0 },
    hiddenText: { x: -60, opacity: 0 },
    visible: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.8
        }
    }
};



export default function Invest(){
    const history = useHistory();
    useEffect(() => {
        
    },[])


    return(
        <>
            <MetaTags>
                <title>{pageData.investor.firstSection.title}</title>
                <meta name="description" content={pageData.investor.firstSection.text} />
                <meta property="og:title" content={pageData.investor.firstSection.title} />
                <meta property="og:image" content={pageData.investor.firstSection.sideImg[1]}/>
            </MetaTags>
            <Hero backColor='#EDEEF0' height="90vh">
                <div>
                    <MainContainer evenly="space-evenly" display='flex' className="main-container">

                        <MainContent className="main-content" initial="hiddenText" animate="visible" variants={item}>
                            <Title>{pageData.investor.firstSection.title}</Title>

                            <Text  weight="500" size="16px">{pageData.investor.firstSection.text}</Text>

                            <div style={{width:'300px', marginTop:'50px'}}>
                                <SubmitButton onClick={()=>history.push('/join')} text="Join Waitlist"/>
                            </div>

                        </MainContent>

                        <SideImg className="main-content">
                            <ImgContainer>
                                {pageData.investor.firstSection.sideImg.map((img, index) => {
                                    return <Image initial="hidden" animate="visible"
                                    variants={index===1? cont:item} img={index} src={img}/>
                                })}
                            </ImgContainer>
                        </SideImg>
                    </MainContainer> 
                </div>
                
            </Hero>

            <div style={{width:"100%", padding:"70px 0px 50px 0px"}}>
            <Gif img={pageData.mainContent.fourthSection.gif} alt="gif" />

                <MainContainer max="1300px" pxAlign="left">
                    <Head>{pageData.investor.secondSection.title}</Head>
                    <Text margin="0px auto" weight="500" size="16px" width="1000px" align='center'>{pageData.investor.secondSection.text}</Text>


                        {pageData.investor.secondSection.tabs.map((item, index) => {
                            return <Cover key={index}> 
                                {index===0 &&<Img><img alt={item.topic} src={item.img}/></Img>}
                                <StyledCover>
                                    <Text width='500px' lineHeight='1.5' pad="0px 0px 10px 0px" size="25px" weight="700" color='black'>{item.topic}</Text>
                                    <Text width='550px' size="15px" weight="500">{item.text}</Text>
                                    
                                        {item.options.map((item, i) => {
                                            return <Pointer key={i}>
                                                <Text size="15px" weight="600" color="#403BF1"><Side>{i+1}</Side>{item.title}</Text>
                                                <Text width='550px' pad="0px 0px 0px 30px" size="15px">{item.text}</Text>
                                            </Pointer>
                                        })}

                                </StyledCover>
                                {index===1 &&<Img><img alt={item.topic} src={item.img}/></Img>}
                            </Cover>
                        })}


                    <Board>
                        <Text minSize="25px" after='1000px' afterSize='35px' afterAlign="center" size='40px' width='700px' weight="700" align="left" color="white">{pageData.investor.thirdSection.title}</Text>
                        <div style={{maxWidth:"250px", height:'auto', padding:'50px 0px'}}>
                            <Text  after='1000px' afterWidth="800px" afterAlign="center" size="14px" color="white">{pageData.investor.thirdSection.text}</Text>
                            <SubmitButton text="Join our waitlist" color="white" textColor="black" textWeight="500"
                                onClick={()=>history.push('/join')}
                            />
                        </div>
                    </Board>
                </MainContainer>

            </div>

        </>
    )
}



const Hero = styled.div`
    width:100%;
    padding-top:${props => props.top ? props.top : '100px'}; 
    position:relative;
    height:${props => props.height};
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    background-color: ${props => props.backColor};
    z-index:10; 
    &>div{
        width:100%;
    }
    @media (max-width: 1000px) {
        height:auto;
    }
    @media (max-width: 560px) {
        padding-top:85px;
    }
`

const MainContainer = styled.div`
    max-width:${props => props.max ? props.max : '1500px'};
    margin:0 auto;
    display:${props => props.display ? props.display : ''};
    justify-content:space-between;
    padding:0px 20px 80px 20px;
    @media (max-width: 1000px) {
        text-align:${props => props.pxAlign ? props.pxAlign : 'center'};
        disaply:flex;
        flex-wrap:wrap;
        justify-content:${props => props.evenly ? props.evenly : ''};
        padding:0px 15px 80px 15px;
    }
`

const MainContent = styled(motion.div)`
    max-width:640px;
    min-width: 320px;
    height:auto;
    padding:0px 0px 0px 50px;
    @media (max-width: 1000px) {
        align-items: center;
        padding:0px 0px 0px 0px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    float: left;
`

const Title = styled.h1`
    font-size:55px;
    font-weight:800;
    line-height:1.5;
    color:black;
    font-family: 'Raleway', sans-serif;
    @media (max-width: 1000px) {
      font-size:40px;
    } 
    @media (max-width: 400px) {
      font-size:35px;
    }
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`




const SideImg = styled.div`
    max-width: 600px;
    display:table;
    margin-top:50px;
    @media (max-width: 850px) {
    }
`;

const ImgContainer = styled.div`
    height: 550px;
    display:table-cell;
    vertical-align:middle;
    text-align:center;
    @media (max-width: 560px) {
        height: 450px;
    }
`;

const Image = styled(motion.img)`
    height:${props => props.img === 0 ? '100px' : props.img === 1 ? '350px':  "50px"};
    position: ${props => props.img === 0 ? 'absolute' : "relative"};
    z-index: ${props => props.img === 0 ? '10' : "0"};
    margin: ${props => props.img === 0 ? '-30px 0px 0px -40px' : props.img ===3? "5px -50px 0px 0px": "5px"};

    @media (max-width: 450px) {
        height:${props => props.img === 0 ? '90px' : props.img === 1 ? '300px':  "45px"};
        margin: ${props => props.img === 0 ? '-15px 0px 0px -20px' : props.img ===3? "5px -50px 0px 0px": "5px"};

    }
`;





const StyledCover = styled(motion.div)`
    disaply:flex;
    flex-direction:column;
    max-width:600px;
    
`;
const Head = styled.h2`
    max-width:${props => props.maxWidth ? props.maxWidth : ''};
    font-size:35px;
    font-weight:700;
    line-height:1.5;
    color:${props => props.color ? props.color : 'black'};
    text-align:${props => props.align ? props.align : 'center'};
    font-family: 'Raleway', sans-serif;
    @media (max-width: 400px) {
      font-size:30px;
    }
`

const Cover = styled.div`
    width:100%;
    height:auto;
    margin-top:80px;
    display:flex;
    flex-wrap:wrap;
    padding:0px 0px 50px 0px;

    justify-content:space-between;
    @media (max-width: 1450px) {
        justify-content:space-evenly;
    }
`;

const Side = styled.div`
    width:20px;
    height:20px;
    margin-right:15px;
    border:1.5px solid #403BF1;
    border-radius:15px;
    align-self:center;
    font-size:10px;
    font-weight:700;
    color:#403BF1;
    text-align:center;
    font-family: 'Open Sans', sans-serif;
`;

const Img = styled.div`
    padding:5px;
    margin:30px 0px;
    width:410px;
    min-height:300px;
    &>img{
        width:100%;
    }
`;

const Pointer = styled.div`
    display:block;
    margin-top:20px;
`;   

const Board = styled.div`
    min-height:250px;
    max-width:1000px;
    margin:80px auto 0px auto;
    padding:30px 50px;
    display:flex;
    ${'' /* flex-wrap:wrap; */}
    background-color: #403BF1;
    border-radius:15px;
    justify-content:space-between;
    align-items:center;
    background: linear-gradient(90deg,#000, #403BF1, #7B7BFF);
    background-size: 200% 200%;
    animation: Animation 4s ease infinite;
    @-webkit-keyframes Animation {
        0%{background-position:10% 0%}
        50%{background-position:91% 100%}
        100%{background-position:10% 0%}
    }
    @-moz-keyframes Animation {
        0%{background-position:10% 0%}
        50%{background-position:91% 100%}
        100%{background-position:10% 0%}
    }
    @keyframes Animation { 
        0%{background-position:10% 0%}
        50%{background-position:91% 100%}
        100%{background-position:10% 0%}
    }
    @media (max-width: 1000px) {
        flex-wrap:wrap;
        align-items:center;
    }
    @media (max-width: 835px) {
        justify-content:center;
    }

`;


const Gif = styled.div`
    position:absolute;
    margin-top: -150px;
    left:-60px;
    width:165px;
    height:150px;
    background-image: url(${props => props.img});
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;
    z-index: 67;
`



