import React from 'react';
import styled from 'styled-components';
import pageData from '../dataProvider/pageData';
import {Text} from '../components/input';



const PageSection_3 = () => {
    return (
        <Container>
            <Head>{pageData.mainContent.thirdSection.title}</Head>
            <Text weight="500" color="#777777" size="15px" width="850px" align="center" margin="0 auto">
                {pageData.mainContent.thirdSection.primaryText}
            </Text>
            <Tabs>
                {pageData.mainContent.thirdSection.tabs.map((tab, index) => {
                    return (
                        <Tab key={index} index={index}>
                            <img src={tab.icon} alt={tab.topic}/>
                            <p>{tab.topic}</p>
                            <b>{tab.text}</b>
                        </Tab>
                    );
                })}
            </Tabs>
        </Container>
    );
}




const Container = styled.div`
    max-width: 1550px;
    margin: 0 auto;
    padding: 0px 20px 20px 20px;
    display: block;
`

const Head=styled.h1`
    font-size: 30px;
    font-weight: 700;
    color: #000;
    font-family: 'Raleway', sans-serif;
    text-align: center;
    padding: 20px;
    margin: 0 auto;
    display: block;
    @media (max-width: 400px) {
      font-size:28px;
    }
`

const Tabs = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 50px 0px 20px 0px;
`
const Tab = styled.div`
    max-width: 290px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 10px;
    text-align: center;
    position: relative;
    &>img{
        width: 100px;
        height: 100px; 
    }
    &>p{
        font-family: 'Raleway', sans-serif;
        font-weight: 700;
        font-size: 18px;
        color: #000;
        width: 100%;
    }
    &>b{
        font-family: 'Open Sans', sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #777777;
        line-height: 2;
    }

`


export default PageSection_3;