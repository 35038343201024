import openstakeLogo from '../images/openstakeLogo.png';
import openstakeLogoWhite from '../images/openstakeLogoWhite.png';
import arrowRight from '../images/arrowRight.png';
import sideImg from '../images/sideImg.svg';
import sideImg2 from '../images/sideImg2.svg';
import sideImg3 from '../images/sideImg3.svg';
import digitalized from '../images/digitalized.svg';
import compliance from '../images/compliance.svg';
import globalIcon from '../images/global.svg';
import liquidity from '../images/liquidity.svg';
import sell from '../images/sell.png';
import dot from '../images/dot.svg';
import background_dot1 from '../images/background_dot1.svg';
import background_dot2 from '../images/background_dot2.svg';
import investors_1 from '../images/investors_1.png';
import investors_2 from '../images/investors_2.png';
import invest_background from '../images/invest_background.svg';
import offering from '../images/offering.svg';
import tech from '../images/tech.png';
import tag from '../images/tag.svg';
import Twitter from '../images/Twitter.svg';
import Facebook from '../images/Facebook.svg';
import Instagram from '../images/Instagram.svg';
import secOffering from '../images/secOffering.png';
import captable from '../images/captable.svg';
import crossborder from '../images/crossborder.svg';







const data = {
    headerComp:{
        logo:openstakeLogo,
        home:{
            name:'Home',
            route:'/'
        },
        invest:{
            name:'Invest',
            route:'/invest'
        },
        raiseCapital:{
            name:'Raise capital',
            route:'/raisecapital'
        },
        community:{
            name:'Community',
            route:null
        },
        join:{
            name:'Join waitlist',
            route:'/join'
        },
    },
    mainContent:{
        title:'Digitalized Private Investment Meets Decentralized Liquidity  ',
        primaryText:" The easiest and fastest way to participate in the private markets. Join our waitlist today for early access to participate in the next round of token offerings.",
        buttonText:'Invest Now',
        buttonIcon:arrowRight,
        sideImg:sideImg,
        secondSection:{
            title:'Decentralized protocol for private investments',
            primaryText: `OpenStake is a platform that allows organizations to raise funds by selling asset-backed securities to investors. 
            Using the blockchain technology, we help organize and structure offerings, giving investors portfolio transparency and security.`,
            tabs:[
                {
                    text:`Built on the Ethereum Network, OpenStake combines the use of numerous 
                    token protocols and blockchain technology, which ensure the 
                    transparency and immutability of equity ownership `,
                    img:sideImg2
                },
                {   
                    text:`Investors can diversify their portfolio by investing in private 
                    companies while startup founders can sell equity in their company 
                    to raise the capital that they need.`,
                    img:sideImg3
                } 
            ]
        },
        thirdSection:{
            title:'What do we offer?',
            primaryText:`We provide a platform that makes investing easier and more accessible for both institutional and individual 
            investors by leveraging decades of technology advancements.`,
            tabs:[
                {
                    topic: 'Digitalized ownership',
                    text:`Security tokens are financial securities of 
                    ownership. Investors of security tokens 
                    can own fractions of private assets stocks`,
                    icon:digitalized
                },
                {
                    topic: 'Compliance',
                    text:`A protocol where any assets/company can issue equity tokens on our platform based on SEC-compliant rules`,
                    icon:compliance
                },
                {
                    topic: 'Global market',
                    text:`Access global private assets and investors 
                    at your finger tip, directly from OpenStake 
                    without banks, portals, or brokers`,
                    icon:globalIcon
                },
                {
                    topic: 'Liquidity',
                    text:`Comprehensive approach to private investment on the blockchain, we help open up liquidity in private markets`,
                    icon:liquidity
                },
            ]
        },
        fourthSection:{
            title:'Never Deal With Illiquid Shares Again',
            primaryText:`Many fundraising platforms allow investors to invest their money into companies, but a lack of liquidity often 
            makes it difficult for those investors to see meaningful returns if any at all.`,
            img:sell,
            gif:dot
        },
        fifthSection:{
            title:'Have a question? Our team will be happy to talk to you ! 😁',
            buttonText:'Contact Us',
            background:[background_dot1,background_dot2]
        }

    },
    getStarted:{
        title:'Join our waitlist',   
        primaryText:"Join the waitlist to be the first to know when the next round of token offerings is available. ",
        role:[
            {   
                key:0,
                role:'investor',
                primaryText:'I want to take part in private investment on the blockchain with OpenStake'
            },
            {
                key:1,
                role:'business',
                primaryText:'I want to be an issuer/ raise capital on the blockchain with OpenStake'
            }
        ]
    },
    investor:{
        firstSection:{
            title:'Invest in private assets on the blockchain',
            text:`OpenStake makes it easy to invest in private assests.  By leveraging blockchain 
            technology, investors can now take control and manage their digital securities.`,
            primaryText:"Join the waitlist to be the first to know when the next round of token offerings is available. ",
            buttonText:'Join Our Waitlist',
            sideImg:[offering,invest_background,investors_1,investors_2]
        },
        secondSection:{
            title:'Why Invest in Private companies with OpenStake?',
            text:`We’re a decentralized protocol for investing in private assets by issuing security tokens, 
            we offer liquidity to investors, allow you to enter pre-offerings, and give investors powerful tools for better investment`,
            tabs:[
                {
                    topic: 'Private Asset stock exchange made easy',
                    text:`You can rest easy knowing that there’s always an exit strategy when investing in 
                    digital securities on OpenStake.`,
                    img:tech,
                    options:[
                        {
                            title:'Blockchain technology',
                            text:`Using blockchain and  Security tokens, we're changing the way 
                            investing works.`
                        },
                        {
                            title:'Add liquidity to your portfolio',
                            text:`Invest in security tokens, unlock liquidity by reselling your shares, get dividends and vote on management`,
                        },
                        {
                            title:'Asset management',
                            text:`We provide investors with an asset management portal to track and review their investments.`
                        },
                    ],


                },
                {
                    topic: 'Cross border investments just got easier.',
                    text:`Access global private assets and investors at your finger tip, directly from OpenStake
                    without banks, portals, or brokers `,
                    img:crossborder,
                    options:[
                        {
                            title:'Find the right investment',
                            text:`OpenStake provides you with the right information, tools, and data to invest safely.`
                        },
                        {
                            title:'Take part in pre-offerings',
                            text:`Participate in companies Test-the-Waters stage, have early access to offerings.`
                        },
                    ]
                },
            ]

        },
        thirdSection:{
            title:'Participate in the next token offering. Join our waitlist now.',
            text:`Let's build a decentralized world together.`,
            tag:tag,
        }
    },
    forBusiness:{
        firstSection:{
            title:'Get the Capital You Need for Your Startup.',
            text:'OpenStake helps startup founders raise the necessary capital to start, fuel, and grow their businesses.',
            button:'Join Waitlist',
            type:'Total funds',
            rasiseCapital:12674288.92,
            slider:[
                {
                    title:'Test-the-waters',
                    number:70,
                    color:'#EDEEF0',
                },
                {
                    title:'Total raised',
                    number:58,
                    color:'#C0C7FA',

                },
                {
                    title:'Investors',
                    number:10,
                    color:'#403BF1',
                },
            ],
        },
        secondSection:{
            title:'Why raise capital with OpenStake?',
            text:`OpenStake is a platform for raising capital. We issue security tokens to represent investment shares in the form of tradition
            financial assets, everything from private companies to real estate, venture capital funds, hedge funds, blockchain companies and token offerings.`,
            tabs:[
                {
                    topic: 'Issue Security Token and Get Capital',
                    text:`Democratizing the global financial market by creating a platform for  
                    issuing, managing and trading security for private assets management.`,
                    img:secOffering,
                    options:[
                        {
                            title:'Dynamic listings & issuance',
                            text:`OpenStake is creating a new generation of private assets powered by
                            security tokens issuance to represent investment shares.`
                        },
                    ],


                },
                {
                    topic: 'Test-the-waters before security offering',
                    text:`We democratize the funding process by giving issuers
                     a safe and effective way to let investors commit before offering securities.`,
                    img:captable,
                    options:[
                        {
                            title:'Cap table management',
                            text:`Our platform allows organizations to issue security tokens, manage 
                            investor relations and raise capital.  We help raise capital the right way, 
                            keep investors in control and give value to them.`
                        },
                    ]
                },
            ],
        },
        thirdSection:{
            title:"We power the best private assets, and we're just getting started.",
            text:`Let's build a decentralized world together.`,
        }
    },
    testWaters:{  
        route:'/test-the-waters',  
        title:'Test-the-waters before your security offerings',
        text:'A safe and effective way to let investors commit before offerings',
        head:{
            title:'Start investing',
            text:"Join waitlist",
            route:'/join'
        },
        input:[
            "Company name",
            "Website",
            "Email",
        ]
    },
    footer:{
        head:{
            img:openstakeLogoWhite,
            title:'Boston, Massachusetts',
            link:'Contact us',
            route:'/contact'
        },
        social:{
            title:'Community',
            links:[
                {name:'Twitter',img:Twitter, route:"https://twitter.com/OpenStakeCo"},
                {name:"Instagram",img:Instagram, route:"https://www.instagram.com/openstake/"},
                {name:"Facebook",img:Facebook, route:"#"},
            ],
        },
        options:[
            {
                head:'Raise capital', 
                texts:{
                    text:'Test the waters',
                    route:'/test-the-waters'
                }
            },
            {
                head:'Invest',
                texts:{
                    text:'Join waitlist',
                    route:'/join'
                }
            }
        ],
        year:'OpenStake © All right reserved 2021'
    },
    
}





export default data;