import React,{useState, useEffect} from "react";
import data from '../dataProvider/pageData';
import {useHistory} from 'react-router-dom';
import { motion } from "framer-motion"
import { SubmitButton, Text } from "../components/input";
import styled from 'styled-components';






const item = {
    hidden: { x: 50, opacity: 0 },
    hiddenText: { x: -60, opacity: 0 },
    visible: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.6
        }
    }
};





export default function RaiseCapital(){
    const history = useHistory();
    const [newPercentage, setNewPercentage] = useState(50);
    const [newDuration, setNewDuration] = useState(25);
    const [numberIncrement, setNumberIncrement] = useState(data.forBusiness.firstSection.rasiseCapital)

    useEffect(() => {
        const interval = setInterval(() => {
            const percent = [78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96]
            const total = [25,26,27,28,29,30,31,32,33,34,35,36,37,38]

            setNewPercentage(percent[Math.floor(Math.random() * percent.length)]);
            setNewDuration(total[Math.floor(Math.random() * total.length)]);
            incremaentNumber();
            
        }, 2500);


        return () => {
          clearInterval(interval);
        };
      }, [numberIncrement]);

    const incremaentNumber = () => {
        const number =(newPercentage/100)* data.forBusiness.firstSection.rasiseCapital;
        setNumberIncrement(number);
        
    }

    return(
        <>
            <Hero backColor='#F1F3FF' height="90vh">

                <div>
                    <MainContainer evenly="space-evenly" display='flex' pad="0px 20px 80px 50px">

                        <MainContent className="main-content" initial="hiddenText" animate="visible" variants={item}>
                            <Title>{data.forBusiness.firstSection.title}</Title>

                            <Text  weight="500" size="16px">{data.forBusiness.firstSection.text}</Text>

                            <div style={{width:'300px', marginTop:'50px'}}>
                                <SubmitButton text={data.forBusiness.firstSection.button}
                                    onClick={()=>history.push('/join')}
                                />
                            </div>

                        </MainContent>

                        <SideImg className="main-content">
                            <Text size="16px" color="#585858" weight="600">{data.forBusiness.firstSection.type}</Text>
                            <div style={{textAlign:'left', padding:'0px 0px 20px 0px'}}>

                            
                            {/* <Text size="26px" color="#403BF1" weight="700">{formatValue(numberIncrement)}</Text> */}

                            </div>
                            {data.forBusiness.firstSection.slider.map((item, index) => {
                                const l= newPercentage;
                                const k = newPercentage-newDuration;
                                const q = ((l-k)/3)*2

                                return <Slider width={`${index===0?l:index===1? k:q}%`} radius={index===0? "15px 0px 0px 0px":'0px 0px 0px 15px'} color={item.color} index={index}>
                                        <Text size='12px' pad="30px 5px 0px 20px"  weight="500" color={index===2? 'white':'#332EEF'}>{item.title}</Text> 
                                </Slider>
                            })}
                        </SideImg>
                    </MainContainer> 
                </div>

            </Hero>

            <div style={{width:"100%", padding:"70px 0px 0px 0px", backgroundColor:"#fff"}}>
            <Gif img={data.mainContent.fourthSection.gif} alt="gif" />

                <MainContainer pad='0px 20px' max="1300px" pxAlign="left" >
                    <div></div>
                    <Head>{data.forBusiness.secondSection.title}</Head>
                    <Text margin="0px auto" weight="500" size="16px" width="1000px" align='center'>{data.forBusiness.secondSection.text}</Text>


                        {data.forBusiness.secondSection.tabs.map((item, index) => {
                            return <Cover key={index}> 
                                {index===0 &&<Img img={index}><img alt={item.topic} src={item.img}/></Img>}
                                <StyledCover>
                                    <div>

                                    <Text width='500px' lineHeight='1.5' pad="0px 0px 10px 0px" size="25px" weight="700" color='black'>{item.topic}</Text>
                                    <Text width='550px' size="14px" weight="500" >{item.text}</Text>
                                    
                                        {item.options.map((item, i) => {
                                            return <Pointer key={i}>
                                                <Text size="15px" weight="600" color="#403BF1"><Side>•</Side>{item.title}</Text>
                                                <Text pad="0px 0px 0px 30px" size="15px">{item.text}</Text>
                                            </Pointer>
                                        })}
                                    </div>

                                </StyledCover>
                                {index===1 &&<Img><img alt={item.topic} src={item.img}/></Img>}
                            </Cover>
                        })}


                </MainContainer>
            </div>

            <div style={{ padding:"100px 20px", backgroundColor:"#EDEEF0"}}>
                <Board>
                    <Text minSize="25px" after='1000px' afterSize='35px' afterAlign="center" size='40px' width='700px' weight="600" align="left" color="black">{data.forBusiness.thirdSection.title}</Text>
                    <div style={{maxWidth:"250px", height:'auto', padding:'50px 0px'}}>
                        <Text  after='1000px' afterWidth="800px" afterAlign="center" size="14px" color="black">{data.forBusiness.thirdSection.text}</Text>
                        <SubmitButton text="Test the waters!" color="#403BF1" textColor="white" textWeight="500"
                            onClick={()=>history.push('/test-the-waters')}
                        />
                    </div>
                </Board>
            </div>
        </>
    )
}












const Hero = styled.div`
    width:100%;
    padding-top:${props => props.top ? props.top : '100px'}; 
    position:relative;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    height:${props => props.height};
    background-color: ${props => props.backColor};
    z-index:10;   
    &>div{
        width:100%;
    }  
    @media (max-width: 1000px) {
        height:auto;
    }
    @media (max-width: 560px) {
        padding-top:85px;
    }
`

const MainContainer = styled.div`
    max-width:${props => props.max ? props.max : '1400px'};
    margin:0 auto;
    display:${props => props.display ? props.display : ''};
    justify-content:space-between;
    padding:${props => props.pad ? props.pad : '0px'};
    @media (max-width: 1000px) {
        text-align:${props => props.pxAlign ? props.pxAlign : 'center'};
        disaply:flex;
        flex-direction:column;
        align-items:center;
        padding:0px 15px 80px 15px;
        justify-content:${props => props.evenly ? props.evenly : ''};
        justify-content:space-evenly;
    }
`

const MainContent = styled(motion.div)`
    max-width:550px;
    min-width: 320px;
    height:auto;
    padding:0px 0px 0px 0px;
    @media (max-width: 1000px) {
        align-items: center;
        padding:0px 0px 0px 0px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    float: left;
`

const Title = styled.h1`
    font-size:50px;
    font-weight:800;
    line-height:1.5;
    color:black;
    font-family: 'Raleway', sans-serif;
    @media (max-width: 1000px) {
      font-size:40px;
    } 
    @media (max-width: 400px) {
      font-size:35px;
    }
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`




const SideImg = styled.div`
    min-width:550px;
    padding:0px 0px 0px 20px;
    display:table;
    margin-top:50px;
    @media (max-width: 1000px) {
        padding:30px 0px 0px 0px;
        width:100%;
        min-width:100%;
    }
`;





const StyledCover = styled(motion.div)`
    max-width:600px;
        position:relative;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;

`;

const Head = styled.h2`
    max-width:${props => props.maxWidth ? props.maxWidth : ''};
    font-size:35px;
    font-weight:700;
    line-height:1.5;
    color:${props => props.color ? props.color : 'black'};
    text-align:${props => props.align ? props.align : 'center'};
    font-family: 'Raleway', sans-serif;
    @media (max-width: 400px) {
      font-size:30px;
    }
`

const Cover = styled.div`
    width:100%;
    height:auto;
    margin-top:80px;
    display:flex;
    flex-wrap:wrap;
    padding:0px 0px 50px 0px;
    justify-content:space-between;
    @media (max-width: 1010px) {
        justify-content:space-evenly;
    }
`;

const Side = styled.div`
    width:15px;
    height:15px;
    margin-right:15px;
    border:1.2px solid #403BF1;
    border-radius:10px;
    align-self:center;
    font-size:8px;
    font-weight:700;
    color:#403BF1;
    text-align:center;
    font-family: 'Open Sans', sans-serif;
`;

const Img = styled.div`
    padding:5px;
    margin:30px 0px;
    width:600px;
    min-height:200px;
    &>img{
        width:100%;
    }
`;

const Slider = styled.div`
    height:150px;
    width:${props => props.width ? props.width : '100px'};
    background-color:${props => props.color ? props.color : '#EDEEF0'};
    margin:${props => props.index>0 ? '-75px 0px 0px 0px' : '0px'};
    border-radius:${props => props.radius? props.radius : ''};
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
    min-width:100px;
`

const Pointer = styled.div`
    display:block;
    margin-top:20px;
`;   

const Board = styled.div`
    min-height:250px;
    max-width:1000px;
    margin:0px auto 0px auto;
    padding:30px 50px;
    display:flex;
    ${'' /* flex-wrap:wrap; */}
    border-radius:15px;
    justify-content:space-between;
    align-items:center;
    @media (max-width: 1000px) {
        flex-wrap:wrap;
        align-items:center;
    }
    @media (max-width: 835px) {
        justify-content:center;
    }
`;


const Gif = styled.div`
    position:absolute;
    margin-top: -150px;
    right:0;
    width:82.5px;
    height:150px;
    background-image: url(${props => props.img});
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;
    z-index: 67;
    overflow: hidden;
`

