import React, {useState} from 'react';
import styled from 'styled-components';
import {TextInput,Text, Selectable, SubmitButton} from '../components/input';
import pageData from '../dataProvider/pageData';
import { motion, AnimatePresence } from "framer-motion"
import { Checkmark } from 'react-checkmark'
import MetaTags from 'react-meta-tags';





// create styled component
const Container = styled(motion.div)`
    max-width:450px;
    min-height:90vh;
    height:auto;
    padding-bottom:100px;
    margin:0 auto;
    padding:100px 20px;
    background-color:white;
`;

const HeaderText = styled.h1`
    font-size:22px;
    font-weight:bold;
    color:#000;
    margin-bottom:10px;
    font-family: 'Open Sans', sans-serif;
    text-align:center;
`;

const Tittle = styled.p`   
    font-size:14px;
    font-weight:400;
    color:#000;
    margin-bottom:50px;
    font-family: 'Open Sans', sans-serif;
    text-align:center;
`

export const Join =()=>{
    const [stage, setStage] = useState(false);
    const [selected, setSelected] = useState(null);
    const [isBusiness, setIsBusiness] = useState(false);
    const [businessName, setBusinessName] = useState('');
    const [email, setEmail] = useState('');
    const [type , setType] = useState(selected?.role);
    const [comment , setComment] = useState('');
    const [error, setError] = useState(false);



    const submitForm = async (e) => {
        e.preventDefault();
        setError('');

        let re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(type=== 'business'){
            if(businessName=== '' || businessName=== null || businessName=== undefined){
                return setError('Business name is required');
            }
        }

        if (!re.test(email)) {
            return setError("Please enter a valid email")
        }
        
        var formId = '1FAIpQLScUWVIMpEqlG2UOlTbNmK427akyolVSK01kvaBuNQKksxwumg'
        var queryString = `formResponse?&entry.334892984=${email}&entry.1166974658=${type}&entry.426740402=${comment}&entry.839337160=${businessName}&submit=SUBMIT`
    
        var url = `https://docs.google.com/forms/d/e/${formId}/${queryString}`
    
        var opts = {
            method: "POST",
            mode: "no-cors",
            redirect: "follow",
            referrer: "no-referrer"
        }
        await fetch(url, opts)
            .then(res => {
            setStage(true);
            setEmail('');
            setBusinessName('');
            setComment('');
            })
            .catch(e => setError("There was an error, please try again"))
    }


    const sendSelected = (data)=>{
        setSelected(data);
        setType(data.role);
    }


     
    return(
        <AnimatePresence>
            <MetaTags>
                <title>{pageData.getStarted.title}</title>
                <meta name="description" content={pageData.getStarted.primaryText} />
                <meta property="og:title" content={pageData.getStarted.title} />
                <meta property="og:image" content={''}/>
            </MetaTags>

            {!stage?<Container initial={{ opacity: 0 }}
                animate={{ opacity: 1 , duration:.3}}
                exit={{ opacity: 0 }}>

                <HeaderText>{pageData.getStarted.title}</HeaderText>

                <Tittle>{pageData.getStarted.primaryText}</Tittle>

                <Text color="red" size="12px">{error}</Text>

                {
                    isBusiness? <GetStarted  setEmail={setEmail} role={selected.role} handleSubmit={()=>submitForm}
                        email={email} businessName={businessName} comment={comment}
                        setBusinessName={setBusinessName} setComment={setComment}   
                    /> : 
                    <ChooseRole sendSelected={sendSelected}/>
                }
                
                <SubmitButton text="Continue" onClick={(e)=>{ isBusiness? submitForm(e) : setIsBusiness(true)}} 
                    icon={true} src={pageData.mainContent.buttonIcon}
                    disabled={selected==null? true:false}
                />
            </Container>
            
            :

            <Completed />}
        </AnimatePresence>
    )
}










const GetStarted = ({
        role, setBusinessName, setComment, setEmail, handleSubmit,
        email, businessName, comment
    }) => {

    var inputBusiness = [
        {label:"Business name", value: businessName, set:setBusinessName, required:true},
        {label:"Email",value: email, set:setEmail, required:true},
        {label:"Comment",value: comment, set:setComment,  required:false}
    ];

    var inputInvestor = [
        {label:"Email",value: email, set:setEmail, required:true},
        {label:"Comment",value: comment, set:setComment, required:false},
    ];

    // check if role is investor or business
    const isRole = role==="business"? inputBusiness: inputInvestor;

    return (    
        <AnimatePresence>

            <Form  initial={{ opacity: 0 , left: -10 }}
            animate={{opacity: 1}}
            exit={{ width: 0,transition: { delay: 0.3, duration: 0.3 }}}>
                <form onSubmit={(e)=>handleSubmit(e)}>
                    {isRole.map((item, index) => {
                        return (
                            <div key={index} style={{padding:'5px 0px'}}>
                                <TextInput value={item.value} 
                                    label={item.label} required={item.required} 
                                    type='text' onChange={item.set} 
                                />
                            </div>)
                    })}
                </form>
            </Form>
        </AnimatePresence>
    );  
};


const Form = styled(motion.div)`
    display: block;
    width: 100%;
    margin: 0 auto;
    border: none;
`;










const ChooseRole =({sendSelected})=>{
    const [isSelected, setIsSelected] = useState(null);

    return(
        <>
            {pageData.getStarted.role.map((item, index) => {
                    return (
                        <div key={index} style={{padding:'5px 0px'}}>
                            <Selectable index={index} onClick={(e)=>{
                                setIsSelected(index);
                                sendSelected({index, role:item.role});
                                }} 
                                selected={isSelected===index? true:false} 
                                title={item.role} label={item.primaryText}
                            />
                        </div>
                    )
                })
            }
        </>
    )
}



const Completed =()=>{
    return(
        <Main>
            <Pad>
                <Text align="center" size="16px" pad="0px 0px 50px 0px">Thanks for joining our waitlist, we have an amazing service waiting for you</Text>

                <Checkmark size='96px' color="#403BF1"/>
            </Pad>
        </Main>
    )
}




const Main = styled.div`
    padding: 0px 30px 50px 30px;
    height:80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`;

const Pad = styled.div`
    max-width: 550px;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;