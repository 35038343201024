import React,{useEffect} from 'react';
import {tsParticles} from "tsparticles";
import {useHistory} from 'react-router-dom';
import pageData from '../dataProvider/pageData';
import { motion } from "framer-motion"
import styled from 'styled-components';
import PageSection2 from '../components/pageSection_2';
import PageSection3 from '../components/pageSection_3';
import PageSection4 from '../components/pageSection_4';
import PageSection5 from '../components/pageSection_5';
import MetaTags from 'react-meta-tags';
import homeMeta from '../images/homeMeta.png'
import {Text} from '../components/input';




const particlesProperties={
    particles: {
            links: {
            enable: true,
            distance: 110,
            color: {
                value:["#CAD0FD", "#D9D9D9"]
            }
        },
        move: {
            color: "#97A3FF",
            enable: true,
            speed: 1,
            outModes: {
                default: "bounce"
            }
        },
        size: {
            value: 0.5
        },
        color: {
            value: ["#4540F8", "#5F5F5F"],
            animation: {
                enable: true,
                speed: 20,
                sync: true
            }
        },
    },

    "interactivity": {
        "events": {
            "onhover": {
                "enable": true,
                "mode": "repulse"
            }
        }
    }
}



const rotateMotion = {
    rest: {ease: "easeOut", duration: 0.2, type: "tween"},
    hover: {
        scale: 1.5, 
        rotate: 90,
        transition: {
            duration: 0.3,
            ease: "easeIn",
            type: "tween"
        }
        
    }
}


const item = {
    hidden: { x: -80, opacity: 0 },
    hiddenImg: { x: 80, opacity: 0 },

    visible: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.5
        }
    },
    visibleText: {
        x: 0,
        opacity: 1,
        transition: {
            delay: 0.4,
            duration: 0.5
        }
    },
    visibleHead: {
        x: 0,
        opacity: 1,
        transition: {
            delay: 0.6,
            duration: 0.5
        }
    }
};


export const Home =()=>{
    const history = useHistory()
    useEffect(() => {

        //tsParticles
        tsParticles.load("tsparticles", {
            ...particlesProperties
        }).then().catch()

        
    },[])

    return(
        <>
            <MetaTags>
                <title>{pageData.mainContent.title}</title>
                <meta name="description" content={pageData.mainContent.primaryText} />
                <meta property="og:title" content={pageData.mainContent.title} />
                <meta property="og:image" content={homeMeta}/>
            </MetaTags>
            <Hero className="hero-body" >
                <div id="tsparticles" style={styles.tsparticles}/>

                <MainContainer className="main-container">

                    <MainContent className="main-content">
                        <Title initial="hidden" animate="visibleHead" variants={item} className="title" style={styles.title}>{pageData.mainContent.title}</Title>

                        <Text width="100%" weight="500" size="16px" initial="hidden" animate="visibleText" variants={item} className="text-primary">{pageData.mainContent.primaryText}</Text>

                        <motion.div initial="hidden" animate="visible" variants={item}>
                        <Button  onClick={()=> history.push('/invest')}
                         initial="rest" whileHover="hover" animate="rest">
                            <span style={styles.buttonText}>{pageData.mainContent.buttonText}</span>
                            <div style={styles.btnImg} className="icon">
                                <motion.img variants={rotateMotion}  style={{height:'100%', verticalAlign:'middle',margin:"0px 0px 10px 0px", backgroundColor:""}} alt='buttonIcon' src={pageData.mainContent.buttonIcon}/>
                            </div>
                        </Button>
                        </motion.div>

                    </MainContent>

                    <SideImg className="main-content">
                        <ImgContainer initial="hiddenImg" animate="visible" variants={item}>
                            <img src={pageData.mainContent.sideImg} alt="sideImg"/>
                        </ImgContainer>
                    </SideImg>
                </MainContainer> 
                
            </Hero>

            <div className="hero-body" style={{width:'100%', height:'auto', backgroundColor:'#F6F6F6', zIndex:'66', paddingTop:'50px'}}>
                <PageSection2/>
            </div>

            <div className="hero-body" style={{width:'100%', height:'auto', backgroundColor:'#fff', zIndex:'66', padding:'50px 0px'}}>
                <PageSection3/>
                <PageSection4/>
                <PageSection5/>
            </div>

        </>
    )
}



const Hero = styled.div`
    width:100%;
    padding-top:100px; 
    position:relative;
    height:90vh; 
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    background-color:white;
    z-index:10; 
    @media (max-width: 1000px) {
        height:auto;
    }
    @media (max-width: 560px) {
        padding-top:85px;
    }
`

const MainContainer = styled.div`
    max-width:1500px;
    margin:0 auto;
    justify-content:space-between;
    padding:0px 0px 80px 0px;
    @media (max-width: 1000px) {
        text-align:center;
        disaply:flex;
        flex-direction:column;
    }

`

const MainContent = styled.div`
    max-width:640px;
    min-width: 320px;
    padding:0px 70px 0px 70px;
    @media (max-width: 560px) {
        padding:0px 20px 0px 20px;
    }
`

const Title = styled(motion.h1)`
    font-size:55px;
    font-weight:800;
    line-height:1.5;
    color:black;
    font-family: 'Raleway', sans-serif;
    @media (max-width: 1000px) {
      font-size:42px;
    } 
    @media (max-width: 400px) {
      font-size:35px;
    }
`


const Button = styled(motion.button)`
    background-color:#4540F8;
    align-items: center;
    display:flex;
    border-radius:5px;
    padding:15px;
    margin:30px 0px 0px 0px;
    justify-content:center;
    width:250px;
    text-align:center;
    border:none;
    cursor:pointer;
    @media (max-width: 1000px) {
        margin: 50px auto 60px auto;
    }
`

const  styles = {
    tsparticles:{
        width:'100%', height:'100px',
        zIndex:'-1',
    },
    buttonText:{
        color:'#ffffff',
        fontSize:'16px',
        fontWeight:'400',
        fontfamily:'Open Sans',
    },
    btnImg:{
        height:'8px',
        width:'8px',
        padding:'10px',
        margin:'0px 0px 0px 15px',
        border:'solid white 1px',
        borderRadius:'50%',
        alignItems:'center',
        verticalAlign:'middle'
    },

};




const SideImg = styled.div`
    max-width: 600px;
    display:table;
    z-index:25;
    @media (max-width: 850px) {
        position:relative;
        width:100%;
        text-align:right;
        padding:0px 0px 0px 0px;
    }
`;

const ImgContainer = styled(motion.div)`
    height: 550px;
    display:table-cell;
    vertical-align:middle;
    &>img{
        height: 100%;
        width: auto;
    }
    @media (max-width: 1260px) {
        height: 450px;
    }

    @media (max-width: 1150px) {
        height: 400px;
    }

    @media (max-width: 560px) {
      height: 350px;
    }

    @media (max-width: 560px) {
      height: 300px;
    }
`;
