import React from 'react';
import styled from 'styled-components';
import data from '../dataProvider/pageData';
import { Text } from './input';
import { useLocation,Link } from 'react-router-dom';
import pagedata from '../dataProvider/pageData'




const AppFooter = () => {
    const location = useLocation();
    const isRoute= location.pathname === pagedata.testWaters.route;


    return (
        <Footer className="footer" style={{display:`${isRoute? 'none':'block'}`}} >
            <SubFooter>
                <Tab>
                    <Img pad="5px" height="25px" src={data.footer.head.img} alt="OpenStakeLogo" />
                    <Text pad="5px" size="14px" color="#ACACAC">{data.footer.head.title}</Text>
                    <Link style={{textDecoration:'none'}} onClick={()=> window.location = "mailto:timmyodunuga@gmail.com"} to='#'><Text  pad="5px" size="14px" color="#ACACAC">{data.footer.head.link}</Text></Link>
                </Tab>

                <Tab>
                    <Text pad="5px" weight="600" size="15px" color="white">{data.footer.social.title}</Text>
                    
                    <div>
                        {data.footer.social.links.map((link, index) => {
                            return (
                                <a target="_blank" rel="noreferrer" key={index} style={{textDecoration:'none'}} href={link.route}>
                                    <Img pad="10px" height="15px" src={link.img} alt={link.name}/>
                                </a>
                            )
                        })}
                    </div>
                    <Link style={{textDecoration:'none'}} to="/"><Text  pad="5px" size="14px" color="#ACACAC">Slack</Text></Link>
                </Tab>

                {data.footer.options.map((option, index) => {
                    return <Tab key={index}>
                        <Text pad="5px" weight="600" size="15px" color="white">{option.head}</Text>
                        <Link style={{textDecoration:'none'}} to={option.texts.route}><Text  pad="5px" size="14px" color="#ACACAC">{option.texts.text}</Text></Link>
                    </Tab>
                })}

            </SubFooter>

            <Text bottom="0px" width="100%" size="12px"  style={{justifyContent:"center"}} pad="20px"  color="white">{data.footer.year}</Text>
        </Footer>
    );
}



const Tab = styled.div`
    width: 200px;
    height:auto;
    display: block;
    padding: 10px;
`;

const Img = styled.img`
    height:${props => props.height? props.height : 'auto'};
    width:${props => props.width? props.width : 'auto'};
    padding:${props => props.pad? props.pad : '0px'};
`;




const Footer=styled.div`
    width:100%;
    height:auto;
    background-color: #212734;
    z-index:66;
    position:relative;
    display:block;
    bottom:0px;
    left:0px;
    right:0px;
    padding:50px 0px 0px 0px;
`;

const SubFooter=styled.div`
    max-width:1200px;
    margin:0 auto;
    padding:20px;
    display:flex;
    justify-content:space-evenly;
    align-items:top;
    flex-wrap:wrap;
`;




export default AppFooter;
