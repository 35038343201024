import React from 'react';
import styled from 'styled-components';
import pageData from '../dataProvider/pageData';
import {Text} from '../components/input';

//create react functional component for page section 2
const PageSection_2 = (props) => {
    return (
        <Container >
            <Head>{pageData.mainContent.secondSection.title}</Head>
            <Text weight="500" color="#777777" size="15px" width="1050px" align="center" margin="0 auto">
                {pageData.mainContent.secondSection.primaryText}
            </Text>
            
            <Tabs>
                {pageData.mainContent.secondSection.tabs.map((text, index) => {
                    return (
                        <Tab img={text.img} index={index} key={index}> 
                            <Line index={index}/>
                            {text.text}
                        </Tab>)
                })}
            </Tabs>
            
        </Container>
    )
}



const Container = styled.div`
    max-width: 1500px;
    margin: 0 auto;
    padding: 0px 20px 20px 20px;
    display: block;
`
const Head=styled.h1`
    font-size: 30px;
    font-weight: 700;
    color: #000;
    font-family: 'Raleway', sans-serif;
    text-align: center;
    padding: 20px;
    margin: 0 auto;
    display: block;
    @media (max-width: 400px) {
      font-size:28px;
    }

`

const Tabs = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 80px 0px 50px 0px;
`
const Line = styled.div`
    position: absolute;
    border-radius: 5px;
    margin-left: -15px;
    width: 4px;
    height: 40px;
    background-color: ${props => props.index===0 ? "#fff":'#403BF1'};
`

const Tab = styled.div`
    background-color: ${props => props.index===0 ? "#403BF1":'#fff'};
    max-width: 500px;
    min-width: 250px;
    border-radius: 10px;
    padding: 40px 40px 80px 40px;
    font-size: 14px;
    margin: 20px;
    display: block;
    text-align: left;
    line-height: 2;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    color: ${props => props.index===0 ? "#fff":'#000'};
    background-image: url(${props => props.img});
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 120px;
    &:hover {
        background-color: ${props => props.index===0 ? "#fff":'#403BF1'};
        color: ${props => props.index===0 ? "#000":'#fff'};
        transition: all 0.3s ease-in-out;
        &:after {
                transition: all 0.3s ease-in-out;
        }
        ${Line} {
            background-color: ${props => props.index===0 ? "#403BF1":'#fff'};
            transition: all 0.3s ease-in-out;
            &:after {
                transition: all 0.3s ease-in-out;
            }
        }
    }
`



export default PageSection_2;