import React,{useState} from "react";
import { SubmitButton, Text, TextInput } from "../components/input";
import styled from "styled-components";
import { Link } from "react-router-dom";
import data from '../dataProvider/pageData';
import MetaTags from 'react-meta-tags';




const TestWaters=()=>{
    const [company, setCompany] = useState("");
    const [domain, setDomain] = useState("");
    const [email, setEmail] = useState("");
    
    return(
        <Main>
            <MetaTags>
                <title>{data.testWaters.title}</title>
                <meta name="description" content={data.testWaters.text} />
                <meta property="og:title" content={data.testWaters.title} />
                <meta property="og:image" content={''}/>
            </MetaTags>
            <Pad>
                <Modal>
                    <p style={{textAlign:'end', fontSize:'12px', color:"#151515",fontWeight:'500', fontFamily:'Open Sans', margin:'0px', paddingBottom:'25px' }}>{data.testWaters.head.title} 
                        <Link style={{textDecoration:"none", color:'#403BF1', paddingLeft:'8px', fontWeight:'600'}} to={data.testWaters.head.route}>
                                {data.testWaters.head.text}</Link>
                    </p>
                    
                    <Text lineHeight='1.5'  size="23px" weight="700" color="black" pad="0px 0px 15px 0px">{data.testWaters.title}</Text>
                    <Text lineHeight='1.5' size="13px" weight="400" color="#787878" pad="0px 0px 25px 0px">{data.testWaters.text}</Text>

                    {data.testWaters.input.map((item, i) => {
                        return <Inline key={i} pad="10px 0px">
                            <TextInput value={i===0?company:i===1?domain:email}
                                label={item} required={true} 
                                type='text' onChange={i===0?setCompany:i===1?setDomain:setEmail} 
                            />
                        </Inline>
                    })}
                    <SubmitButton/>

                    <Text size="12px" style={{justifyContent:'center'}}  pad="30px 0px 0px 0px"  color="#929292">{data.footer.year}</Text>
                </Modal>
            </Pad>  
        </Main>
    );
}

export default TestWaters;


const Main = styled.div`
    padding-top:100px;
    min-height:90vh;
    padding-bottom:100px;
    width: 100%;
    height: auto;
    display: block;
    background-color: #EDEEF0;
`;

const Pad = styled.div`  
    margin: 20px auto;
    padding: 0px 20px;
    height: auto;
`;

const Modal = styled.div`
    max-width:480px;
    margin:0px auto;
    border-radius:15px;
    padding:30px 30px 10px 30px;
    disaply:block;
    position: relative;
    background-color: white;
`

const Inline = styled.div`
    display: ${props => props.flex ? props.flex : ''};
    width:100%;
    text-align:${props => props.align ? props.align : ''};
    height:${props => props.height? props.height : 'auto'};
    padding:${props => props.pad? props.pad : '0px'};   
`
